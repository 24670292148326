import { useState } from 'react'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"
import pica from 'pica'

export default function Upload({uid}) {
    const [selectedFile, setSelectedFile] = useState(null)
    const [imageWidth, setImageWidth] = useState(0)
    const [downloadUrl, setDownloadUrl] = useState()
    const [isUploading, setIsUploading] = useState(false)

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0])
        const file = event.target.files[0]
        if (file && file.type.startsWith('image/')) {
            const tempUrl = URL.createObjectURL(file)
            const img = new Image()
            img.onload = () => {
                setImageWidth(img.width)
                URL.revokeObjectURL(tempUrl)
            }
            img.src = tempUrl
        }
    }

    const resizeImage = async (file) => {
        const targetWidth = 840
        const reader = new FileReader()
        const canvas = document.createElement('canvas')
        const picaResizer = pica()
    
        return new Promise((resolve, reject) => {
            reader.onload = (event) => {
                const img = new Image()
                img.onload = async () => {
                    const aspectRatio = img.height / img.width
                    const targetHeight = targetWidth * aspectRatio
                    canvas.width = targetWidth
                    canvas.height = targetHeight
                    await picaResizer.resize(img, canvas)
                        .then(result => picaResizer.toBlob(result, 'image/jpeg', 0.90))
                        .then(blob => resolve(blob))
                        .catch(error => reject(error));
                }
                img.onerror = (error) => {
                    reject(error)
                }
                img.src = event.target.result
            };
            reader.onerror = (error) => {
                reject(error)
            }
            reader.readAsDataURL(file)
        })
    }
    

    const uploadImage = async () => {
        setIsUploading(true)
        const resizedImage = await resizeImage(selectedFile, 1024, 1024)
        const storage = getStorage()
        const storageRef = ref(storage, `uploads/${uid}/${selectedFile.name}`)
        try {
            const snapshot = await uploadBytes(storageRef, resizedImage)
            const downloadURL = await getDownloadURL(snapshot.ref)
            setDownloadUrl(downloadURL)
            setIsUploading(false)
        } catch (error) {
            console.error('Upload failed', error)
            setIsUploading(false)
        }
    }

    return (
        <div className="max-w-xl mx-auto my-12 p-4">
            {!isUploading && !downloadUrl &&
                <>
                    <h1 className="font-bold text-xl">Step 1: Upload an event image</h1>
                    <p className='mb-6'>It must be at least 840px wide.</p>
                    <input type="file" onChange={handleFileChange} />
                    {imageWidth > 0 && imageWidth < 840 && <p>The image is {imageWidth}px wide. It must be at least 840px wide.</p>}
                    {imageWidth > 800 &&
                        <button onClick={uploadImage} type="button" className="block mt-8 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            Upload Event Image
                        </button>
                    }
                </>
            }
            {isUploading && <h1 className="font-bold text-xl">Uploading file...</h1>}
            {!isUploading && downloadUrl &&
                <>
                    <h1 className="font-bold text-xl">Step 2: Copy & Paste URL back into Sidebar</h1>
                    <p className='mb-6'>After copying, go back to the form in Sidebar Ticketing and paste the URL. Once that is done you can close this page.</p>
                    <code class="bg-gray-100 text-sm font-mono text-gray-900 p-2 rounded-md">
                        {downloadUrl}
                    </code>
                    <button onClick={async () => await navigator.clipboard.writeText(downloadUrl)} type="button" className="block mt-8 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Copy URL
                    </button>
                </>
            }
        </div>
    )
}