import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"
import Homepage from './Homepage'
import App from './App'
import Success from './Success'
import Terms from './Terms'
import Privacy from './Privacy'
import Ticket from './Ticket'
import ExpiredLoginLink from './ExpiredLoginLink'
import Upload from './Upload'
import AuthRequired from './AuthRequired'
import AuthLoading from './AuthLoading'
import Thanks from './Thanks'

const firebaseConfig = {
    apiKey: "AIzaSyDMVjs_rLfunpqPTdkMAAIgPTjGDk0H4tM",
    authDomain: "sidebar-de524.firebaseapp.com",
    projectId: "sidebar-de524",
    storageBucket: "sidebar-de524.appspot.com",
    messagingSenderId: "732446536120",
    appId: "1:732446536120:web:164f2634ffae5f98049f4d"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const functions = getFunctions(app)

const root = ReactDOM.createRoot(document.getElementById('root'))
var urlParams = new URLSearchParams(window.location.search)

async function authenticateWithToken (token) {
    let decodedString
    try {
        decodedString = decodeURIComponent(window.atob(token))
    } catch (e) {
        decodedString = token
        console.log(e)
    }
    try {
        signInWithCustomToken(auth, decodedString)
    } catch (error) {
        console.log(error)
        root.render(<ExpiredLoginLink />)
    }
}

async function authenticateWithStripe (blob) {
    try {
        const decodedString = decodeURIComponent(window.atob(blob))
        const payload = JSON.parse(decodedString)
        const firebaseResponse = await fetch(`https://api.sidebarticketing.com/f/auth-with-stripe`, {
            method: 'POST',
            headers: {
                'Stripe-Signature': payload.signature,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: payload.userId,
                account_id: payload.accountId,
            }),
        })
        let token = false
        if (firebaseResponse.status === 200) {
            token = await firebaseResponse.text()
        } else {
            console.log(firebaseResponse.status)
            root.render(<ExpiredLoginLink />)
        }
        if (token) signInWithCustomToken(auth, token)
    } catch (error) {
        console.log(error)
        root.render(<ExpiredLoginLink />)
    }
}

onAuthStateChanged(auth, (user) => {
    if (urlParams.has('cid')) {
        root.render(<Success functions={functions} />)
    } else if (window.location.pathname === "/thank-you") {
        root.render(<Thanks />)
    } else if (window.location.pathname.slice(0,7) === "/ticket") {
        root.render(<Ticket />)
    } else if (window.location.pathname === "/terms") {
        root.render(<Terms />)
    } else if (window.location.pathname === "/privacy") {
        root.render(<Privacy />)
    } else if (window.location.pathname === "/upload") {
        if (user) {
            root.render(<Upload uid={user.uid} />)
        } else if (urlParams.has('x')) {
            root.render(<AuthLoading />)
            const blob = urlParams.get('x')
            authenticateWithStripe(blob).catch(error => {
                root.render(<ExpiredLoginLink />)
            })
        } else {
            root.render(<AuthRequired />)
        }
    } else if (window.location.pathname === "/app") {
        if (user) {
            root.render(<App uid={user.uid} db={db} auth={auth} functions={functions} initializeWithEvent={urlParams.has('e') ? urlParams.get('e') : ""} />)
        } else if (urlParams.has('x')) {
            root.render(<AuthLoading />)
            const blob = urlParams.get('x')
            authenticateWithStripe(blob).catch(error => {
                console.log(error)
                root.render(<ExpiredLoginLink />)
            })
        } else if (urlParams.has('t')) {
            root.render(<AuthLoading />)
            const token = urlParams.get('t')
            authenticateWithToken(token).catch(error => {
                console.log(error)
                root.render(<ExpiredLoginLink />)
            })
        } else {
            root.render(<AuthRequired />)
        }  
    } else {
        console.log(user)
        root.render(<Homepage isSignedIn={user ? true : false} auth={auth}/>)
    }
})