import React from 'react'

const AccountIcon = ({ accountId, size }) => {
    const imageUrl = `https://firebasestorage.googleapis.com/v0/b/sidebar-de524.appspot.com/o/account_images%2F${accountId}_circle_${size}.png?alt=media`
    const handleImageError = (e) => {
        e.target.style.display = 'none'
    }
    return (
        <img className='h-11 w-11 mr-2' src={imageUrl} alt="logo" onError={handleImageError} />
    )
}

export default AccountIcon