import { useState, useEffect, Fragment } from 'react'
import { collection, query, where, limit, onSnapshot, doc, updateDoc, getDoc, orderBy, serverTimestamp } from "firebase/firestore"
import { useZxing } from "react-zxing"
import QRCode from 'qrcode' // backup one to check out https://github.com/scanapp-org/html5-qrcode-react
import { CheckIcon, CloudArrowDownIcon, FunnelIcon, NoSymbolIcon, TicketIcon, ExclamationTriangleIcon, DocumentIcon, HandThumbUpIcon, ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon, ChevronLeftIcon, CameraIcon } from '@heroicons/react/24/outline'
import { FunnelIcon as FunnelIconSolid } from '@heroicons/react/24/solid'
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom"
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter"
import { isMobile } from 'react-device-detect'
import { Popover, Transition } from '@headlessui/react'

export default function Event({events, db, uid, auth, scopedSearchKey, setSelectedEvents}) {
    const [tickets, setTickets] = useState([])
    const [isScanning, setIsScanning] = useState(false)
    const [result, setResult] = useState("")
    const [lastScanned, setLastScanned] = useState(null)
    const [isShowingRedeemed, setIsShowingRedeemed] = useState(true)
    const [isMidScan, setIsMidScan] = useState(false)
    const [wasSearchRedeemed, setWasSearchRedeemed] = useState("")
    const [isSearching, setIsSearching] = useState(false)
    const [isLoadingPDF, setIsLoadingPDF] = useState(false)
    const [hasDownloaded, setHasDownloaded] = useState(false)
    const [errorLoadingPDF, setErrorLoadingPDF] = useState(false)
    const [now, setNow] = useState(new Date())
    const [animationClass, setAnimationClass] = useState("hidden")
    const [overTwoHundred, setOverTwoHundred] = useState(false)

    let searchClient

    if (scopedSearchKey !== "") {
        const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
            server: {
                apiKey: scopedSearchKey,
                nodes: [
                    {
                        host: 'zc9gjl1a30w6n8mip-1.a1.typesense.net',
                        port: '443',
                        protocol: 'https'
                    },
                ],
            },
            additionalSearchParameters: {
                query_by: "buyerName,buyerEmail,ticketId",
                filter_by: `paymentLinkId:=[${events}]`
            },
        })
        searchClient = typesenseInstantsearchAdapter.searchClient
    }
    const Hit = ({ hit }) => (
        <div className='border border-neutral-900 dark:border-neutral-100 border-dashed rounded p-3 mt-2'>
            <p className="font-medium">{hit.buyerName}</p>
            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{hit.buyerEmail}</p>
            {hit.selectedDate && 
                <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed flex flex-row gap-1 items-center">
                    {hit.selectedDate} {hit.selectedTimeSlot && hit.selectedTimeSlot}
                </p>
            }
            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{hit.description}{hit.amount === "bundle" ? " (purchased in a bundle)" : hit.amount && hit.currency && ` - ${formatCurrency(hit.amount, hit.currency)}`}</p>
            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{hit.ticketId}</p>
            {hit.customFields && JSON.parse(hit.customFields).filter(i => i.value).map(field => (
                <p key={field.key} className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{field.label}: {field.value}</p>
            ))}
            {wasSearchRedeemed === hit.ticketId
                ?
                    <>
                        {isMidScan &&
                            <span className='mt-2 flex items-center justify-center'>
                                <CloudArrowDownIcon className="h-5 w-5 mr-1" />
                                <p>Checking...</p>
                            </span>
                        }
                        {!isMidScan && result === "valid" &&
                            <span className='mt-2 rounded flex border border-green-700 bg-green-400 text-neutral-900 items-center justify-center'>
                                <CheckIcon className="h-5 w-5 mr-1" />
                                <p>Valid</p>
                            </span>
                        }
                        {!isMidScan && result === "invalid" &&
                            <span className='mt-2 rounded flex border border-red-700 bg-red-400 text-neutral-900 items-center justify-center'>
                                <NoSymbolIcon className="h-5 w-5 mr-1" />
                                <p>Not valid</p>
                            </span>
                        }
                        {!isMidScan && result === "refunded" &&
                            <button
                                type='button'
                                onClick={() => searchRedeemAnyway(hit.ticketId)}
                                className='mt-2 w-full rounded flex px-2.5 py-1.5 text-xs font-medium border border-yellow-700 bg-yellow-200 text-neutral-900  items-center justify-center'
                            >
                                <ExclamationTriangleIcon className="h-4 w-4 mr-1" />
                                <p>Refunded. Tap to redeem anyway.</p>
                            </button>
                        }
                    </>
                :
                    <button
                        type="button"
                        onClick={() => attemptSearchRedeem(hit.ticketId)}
                        className="mt-2 w-full items-center roundedpx-2.5 py-1.5 text-xs font-medium border border-neutral-900 dark:border-neutral-100"
                    >
                        Attempt Redeem
                    </button>
            }
        </div>
    )

    useEffect(() => {
        const q = query(collection(db, "tickets"), where("accountId", "==", uid), where("paymentLinkId", "in", events), orderBy("buyerName"), limit(200))
        return onSnapshot(q, querySnapshot => {
            if (querySnapshot.size === 200) {
                setOverTwoHundred(true)
            } else {
                const tickets = []
                querySnapshot.forEach((doc) => {
                    let slotStart
                    let slotEnd
                    if (doc.data().selectedDate) {
                        let startTime = '00:00:00'
                        let endTime = '00:00:00'
                        if (doc.data().selectedTimeSlot) {
                            const timeParts = doc.data().selectedTimeSlot.split(" - ")
                            if (timeParts[0].includes("am")) {
                                const parts = timeParts[0].trim().slice(0, -2).trim().split(":")
                                startTime = parts[0].padStart(2,'0') + ":" + (parts[1] || "00") + ":00"
                            } else if (timeParts[0].includes("pm")) {
                                const parts = timeParts[0].trim().slice(0, -2).trim().split(":")
                                const newPart0 = parseInt(parts[0]) + 12
                                startTime = newPart0 + ":" + (parts[1] || "00") + ":00"
                            }
                            if (timeParts[1] && timeParts[1].includes("am")) {
                                const parts = timeParts[1].trim().slice(0, -2).trim().split(":")
                                endTime = parts[0].padStart(2,'0') + ":" + (parts[1] || "00") + ":00"
                            } else if (timeParts[1] && timeParts[1].includes("pm")) {
                                const parts = timeParts[1].trim().slice(0, -2).trim().split(":")
                                const newPart0 = parseInt(parts[0]) + 12
                                endTime = newPart0 + ":" + (parts[1] || "00") + ":00"
                            }
                        }
                        slotStart = new Date(`${doc.data().selectedDate}T${startTime}`)
                        slotEnd = new Date(`${doc.data().selectedDate}T${endTime}`)
                    }
                    QRCode.toDataURL(doc.id).then(uri => {
                        tickets.push({
                            ...doc.data(),
                            qrcode: uri,
                            ...(slotStart && {slotStart}),
                            ...(slotEnd && {slotEnd})
                        })
                    })
                })
                setTickets(tickets)
            }
        })
    },[db, events, uid])
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            setNow(new Date())
        }, 1000*10)
        return () => clearInterval(intervalId)
    }, [])

    const { ref } = useZxing({
        onDecodeResult(result) {
            attemptRedeem(result.getText())
        },
        paused: !isScanning
    })

    const toggleRedeem = async (id, redeemed) => {
        const docRef = doc(db, "tickets", id)
        await updateDoc(docRef, {
            redeemed: !redeemed,
            mostRecentRedemptionChange: serverTimestamp()
        })
    }

    const playBeep = (frequency = 520, duration = 100) => {
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
        const oscillator = audioCtx.createOscillator()
        oscillator.type = 'square'
        oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime)
        oscillator.connect(audioCtx.destination)
        oscillator.start()
        oscillator.stop(audioCtx.currentTime + duration / 1000)
    }

    const playErrorBeep = (frequency = 260, duration = 100, count = 2, gap = 50) => {
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
        let startTime = audioCtx.currentTime
        for (let i = 0; i < count; i++) {
            const oscillator = audioCtx.createOscillator()
            oscillator.type = 'square'
            oscillator.frequency.setValueAtTime(frequency, startTime)
            oscillator.connect(audioCtx.destination)
            oscillator.start(startTime)
            oscillator.stop(startTime + duration / 1000)
            startTime += (duration + gap) / 1000
        }
    }

    const attemptRedeem = async (id) => {
        if (lastScanned && lastScanned.id === id) {
            return
        } else {
            setIsMidScan(true)
            try {
                const docRef = doc(db, "tickets", id)
                const docSnap = await getDoc(docRef)
                const promises = []
                if (docSnap.exists) {
                    const docData = docSnap.data()
                    if (docData.redeemed) {
                        setLastScanned({
                            id: docSnap.id,
                            data: docData,
                            icon: NoSymbolIcon,
                            color: 'red',
                            status: 'invalid'
                        })
                        playErrorBeep()
                    } else if (docData.refundDate) {
                        setLastScanned({
                            id: docSnap.id,
                            data: docData,
                            icon: ExclamationTriangleIcon,
                            color: 'yellow',
                            status: 'refunded'
                        })
                        playErrorBeep()
                    } else if (!events.includes(docData.paymentLinkId)) {
                        setLastScanned({
                            id: docSnap.id,
                            data: docData,
                            icon: ExclamationTriangleIcon,
                            color: 'yellow',
                            status: 'wrong-event'
                        })
                        playErrorBeep()
                    } else if (docData.selectedDate) {
                        let slotStart
                        let slotEnd
                        let startTime = '00:00:00'
                        let endTime = '00:00:00'
                        if (docData.selectedTimeSlot) {
                            const timeParts = docData.selectedTimeSlot.split(" - ")
                            if (timeParts[0].includes("am")) {
                                const parts = timeParts[0].trim().slice(0, -2).trim().split(":")
                                startTime = parts[0].padStart(2,'0') + ":" + (parts[1] || "00") + ":00"
                            } else if (timeParts[0].includes("pm")) {
                                const parts = timeParts[0].trim().slice(0, -2).trim().split(":")
                                const newPart0 = parseInt(parts[0]) + 12
                                startTime = newPart0 + ":" + (parts[1] || "00") + ":00"
                            }
                            if (timeParts[1] && timeParts[1].includes("am")) {
                                const parts = timeParts[1].trim().slice(0, -2).trim().split(":")
                                endTime = parts[0].padStart(2,'0') + ":" + (parts[1] || "00") + ":00"
                            } else if (timeParts[1] && timeParts[1].includes("pm")) {
                                const parts = timeParts[1].trim().slice(0, -2).trim().split(":")
                                const newPart0 = parseInt(parts[0]) + 12
                                endTime = newPart0 + ":" + (parts[1] || "00") + ":00"
                            }
                        }
                        slotStart = new Date(`${docData.selectedDate}T${startTime}`)
                        slotEnd = new Date(`${docData.selectedDate}T${endTime}`)
                        const now = new Date()
                        if (now < slotStart || now > slotEnd) {
                            setLastScanned({
                                id: docSnap.id,
                                data: docData,
                                icon: ExclamationTriangleIcon,
                                color: 'yellow',
                                status: 'wrong-slot'
                            })
                            playErrorBeep()
                        } else {
                            setLastScanned({
                                id: docSnap.id,
                                data: docData,
                                icon: CheckIcon,
                                color: 'green',
                                status: "valid"
                            })
                            playBeep()
                            promises.push(updateDoc(docRef, {
                                redeemed: true,
                                mostRecentRedemptionChange: serverTimestamp()
                            }))
                        }
                    } else {
                        setLastScanned({
                            id: docSnap.id,
                            data: docData,
                            icon: CheckIcon,
                            color: 'green',
                            status: "valid"
                        })
                        playBeep()
                        promises.push(updateDoc(docRef, {
                            redeemed: true,
                            mostRecentRedemptionChange: serverTimestamp()
                        }))
                    }
                    setAnimationClass('fade-in-scale-up')
                    setTimeout(() => {
                        setAnimationClass('fade-out-scale-down')
                    }, 1000)
                    setIsMidScan(false)
                    await Promise.all(promises)
                }
            } catch (error) {
                setIsMidScan(false)
            }
        }
    }

    const attemptSearchRedeem = async (id) => {
        setWasSearchRedeemed(id)
        setIsMidScan(true)
        const docRef = doc(db, "tickets", id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists && !docSnap.data().redeemed) {
            if (docSnap.data().refundDate) {
                setResult("refunded")
                setIsMidScan(false)
            } else {
                setResult("valid")
                setIsMidScan(false)
                await updateDoc(docRef, {
                    redeemed: true,
                    mostRecentRedemptionChange: serverTimestamp()
                })
            }
        } else {
            setResult("invalid")
            setIsMidScan(false)
        }
    }

    const searchRedeemAnyway = async (id) => {
        setWasSearchRedeemed(id)
        setIsMidScan(true)
        const docRef = doc(db, "tickets", id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists && !docSnap.data().redeemed) {
            setResult("valid")
            setIsMidScan(false)
            await updateDoc(docRef, {
                redeemed: true,
                mostRecentRedemptionChange: serverTimestamp()
            })
        } else {
            setResult("invalid")
            setIsMidScan(false)
        }
    }

    const toggleScannerSearch = () => {
        setIsScanning(!isScanning)
        setLastScanned(null)
        setResult("")
        setWasSearchRedeemed("")
    }

    const redeemAnyway = async (id) => {
        setIsMidScan(true)
        const docRef = doc(db, "tickets", id)
        setResult("valid")
        await updateDoc(docRef, {
            redeemed: true,
            mostRecentRedemptionChange: serverTimestamp()
        })
        playBeep()
        setLastScanned(lastScanned => ({
            ...lastScanned,
            status: "valid",
            color: "green"
        }))
        setIsMidScan(false)
    }

    const clearSearch = () => {
        setIsSearching(false)
        setLastScanned(null)
        setResult("")
        setWasSearchRedeemed("")
    }
    
    function formatCurrency(amount, currencyCode) {
        try {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyCode,
            })
            return formatter.format(amount / 100)            
        } catch {
            return ""   
        }
    }
    
    function changeEvents() {
        setSelectedEvents([])
    }

    const downloadAttendeeList = async (includeCustomFields) => {
        setIsLoadingPDF(true)
        const timestamp = new Date().toLocaleString()
        const idToken = await auth.currentUser.getIdToken()
        const response = await fetch('https://api.sidebarticketing.com/f/get-pdf-attendee-list', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId: uid,
                eventIds: events,
                timestamp,
                ...(includeCustomFields && {includeCustomFields: true})
            }),
        })
        if (response.status === 200) {
            console.log(response.status)
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'attendee_list.pdf'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            setIsLoadingPDF(false)
            setHasDownloaded(true)
            setTimeout(() => {
                setHasDownloaded(false)
            }, 3000)
        } else {
            setIsLoadingPDF(false)
            setErrorLoadingPDF(true)
        }
    }

    const downloadCsv = async () => {
        setIsLoadingPDF(true)
        const timestamp = new Date().toLocaleString()
        const idToken = await auth.currentUser.getIdToken()
        const response = await fetch('https://api.sidebarticketing.com/f/get-csv-tickets-list', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId: uid,
                eventIds: events,
                timestamp
            }),
        })
        if (response.status === 200) {
            console.log(response.status)
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'tickets.csv'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            setIsLoadingPDF(false)
            setHasDownloaded(true)
            setTimeout(() => {
                setHasDownloaded(false)
            }, 3000)
        } else {
            setIsLoadingPDF(false)
            setErrorLoadingPDF(true)
        }
    }

    function resetPDFLinks() {
        setErrorLoadingPDF(false)
        setHasDownloaded(false)
        setIsLoadingPDF(false)
    }

    return (
        <div>
            {isScanning &&
                <div className="touch-none h-screen">
                    <video ref={ref} className="scanner" />
                    <div className="placeholder">
                        {lastScanned &&
                            <div className={`status-container ${animationClass} ${lastScanned.color}`}>
                                <lastScanned.icon className="status-icon" style={{color: lastScanned.color}} />
                            </div>
                        }
                    </div>
                    {!lastScanned && <p className="mx-4 h-[36vh] flex justify-center items-center border-dashed border-2 border-neutral-500 dark:border-neutral-400 rounded-lg mt-4">Ready to scan...</p>}
                    {lastScanned &&
                        <div className={`border-2 border-neutral-500 dark:border-neutral-400 border-dashed rounded p-3 mt-2 mx-4 bg-neutral-50 dark:bg-neutral-900`}>
                            {lastScanned.color === "red" &&
                                <div className="text-white flex justify-between items-center bg-red-800 p-2 rounded mb-2">
                                    <p>Previously redeemed {lastScanned.data.mostRecentRedemptionChange.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'})}</p>
                                    <NoSymbolIcon className='h-8 w-8' style={{color: "white"}} />
                                </div>
                            }
                            {lastScanned.color === "green" &&
                                <div className="text-white flex justify-between items-center bg-green-800 p-2 rounded mb-2">
                                    <p>Valid for entry</p>
                                    <CheckIcon className='h-8 w-8' style={{color: "white"}} />
                                </div>
                            }
                            {lastScanned.color === "yellow" &&
                                <>
                                    <div className="text-white flex justify-between items-center bg-yellow-800 p-2 rounded mb-2">
                                        {lastScanned.status === "refunded" &&
                                            <p>Refunded on {lastScanned.data.refundDate && lastScanned.data.refundDate.toDate().toLocaleString()}</p>
                                        }
                                        {lastScanned.status === "wrong-event" &&
                                            <p>Different one of your events</p>
                                        }
                                        {lastScanned.status === "wrong-slot" &&
                                            <p>Not the current time slot</p>
                                        }
                                        <ExclamationTriangleIcon className='h-8 w-8' style={{color: "white"}} />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => redeemAnyway(lastScanned.id)}
                                        className="rounded p-2 font-medium border border-neutral-900 dark:border-neutral-100 w-full mb-2"
                                        disabled={isMidScan}
                                    >
                                        {isMidScan ? "Redeeming..." : "Redeem Anyway"}
                                    </button>
                                </>
                            }
                            <p className="font-medium">{lastScanned.data.eventName}, {lastScanned.data.description}</p>
                            {lastScanned.data.selectedDate && 
                                <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{lastScanned.data.selectedDate} {lastScanned.data.selectedTimeSlot && lastScanned.data.selectedTimeSlot}</p>
                            }
                            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{lastScanned.data.buyerName}</p>
                            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{lastScanned.data.buyerEmail}</p>
                            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{lastScanned.id}</p>
                        </div>
                    }
                </div>
            }
            {!isScanning &&
                <>
                    <span className='flex items-center justify-between m-4'>
                        <span className={isMobile ? "w-20" : "w-56"}>
                            <button
                                type="button"
                                onClick={changeEvents}
                                className="inline-flex items-center rounded px-2.5 py-1.5 text-xs font-medium border border-neutral-900 dark:border-neutral-100"
                            >
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </span>
                        <h2 className="text-3xl font-extrabold tracking-tight">Tickets</h2>
                        {!isMobile &&
                            <Popover className="relative">
                                <Popover.Button className="inline-flex items-center rounded border border-neutral-900 dark:border-neutral-100 px-2.5 h-10 text-xs font-medium shadow-sm hover:bg-neutral-200 hover:dark:bg-neutral-800 focus:outline-none">
                                    <DocumentIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                    Download PDF or CSV
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 flex w-screen max-w-min -translate-x-1/2 px-4">
                                        <div className="flex flex-col w-56 shrink rounded-xl bg-white dark:bg-black p-4 text-sm font-semibold leading-6 shadow-lg border border-neutral-200 dark:border-neutral-700">
                                            {!isLoadingPDF && !hasDownloaded && !errorLoadingPDF &&
                                                <>
                                                    <p className='font-normal'>Which would you like?</p>
                                                    <button className="underline self-start" onClick={() => downloadAttendeeList(false)}>PDF simple</button>
                                                    <button className="underline self-start" onClick={() => downloadAttendeeList(true)}>PDF all fields</button>
                                                    <button className="underline self-start" onClick={() => downloadCsv(true)}>CSV all fields</button>
                                                </>
                                            }
                                            {isLoadingPDF && <div>Generating...</div>}
                                            {!isLoadingPDF && hasDownloaded && 
                                                <div>Done. Check your Downloads.</div>
                                            }
                                            {errorLoadingPDF &&
                                                <div>
                                                    <p>There was an error downloading the PDF.</p>
                                                    <button className="underline self-start" onClick={resetPDFLinks}>Reset</button>
                                                </div>
                                            }
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </Popover>
                        }
                        {isMobile && 
                            <span className="w-20 flex justify-end">
                                <button
                                    type="button"
                                    onClick={toggleScannerSearch}
                                    className="inline-flex gap-1 items-center rounded p-1.5 w-auto font-medium border border-neutral-900 dark:border-neutral-100"
                                >
                                    <CameraIcon className="h-5 w-5" aria-hidden="true" /> 
                                    Scan
                                </button>
                            </span>
                        }
                    </span>
                    {searchClient &&
                        <div className='m-4'>
                            <InstantSearch searchClient={searchClient} indexName="sidebar-tickets">
                                <div className="flex gap-2">
                                <SearchBox
                                    submit={null}
                                    reset={
                                        <div
                                            className="mt-2 w-full items-center rounded px-2.5 py-1.5 text-xs font-medium border border-neutral-900 dark:border-neutral-100"
                                        >
                                            Clear Search
                                        </div>
                                    }
                                    onReset={clearSearch}
                                    onChange={(e) => {e.target.value === "" ? clearSearch() : setIsSearching(true)}}
                                    translations={{placeholder: 'Search name, email, or code...'}}
                                    className="flex-1"
                                />
                                {!overTwoHundred &&
                                    <button
                                        type="button"
                                        onClick={() => setIsShowingRedeemed(!isShowingRedeemed)}
                                        className="inline-flex items-center rounded px-2.5 py-1.5 text-xs font-medium border border-neutral-900 dark:border-neutral-100"
                                    >
                                        {isShowingRedeemed
                                            ?
                                                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                                            :
                                                <FunnelIconSolid className="h-5 w-5" aria-hidden="true" />
                                        }
                                    </button>
                                }
                                </div>
                                {isSearching && <Hits hitComponent={Hit} />}
                            </InstantSearch>
                        </div>
                    }
                    {!isSearching &&
                        <ul className="relative z-0">
                            {tickets.map(t => ((!t.redeemed || (t.redeemed && isShowingRedeemed)) &&
                                <li key={t.ticketId}
                                    className={`border border-neutral-700 dark:border-neutral-200 border-dashed rounded mx-4 p-3 mt-2 ${t.redeemed ? "opacity-50" : ""}`}
                                    onClick={() => toggleRedeem(t.ticketId, t.redeemed)}
                                >
                                    <div className="flex justify-between items-center">
                                        <span className={t.redeemed ? "line-through" : ""}>
                                            <p className="font-medium">{t.buyerName}</p>
                                            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{t.buyerEmail}</p>
                                            {t.selectedDate && 
                                                <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed flex flex-row gap-1 items-center">
                                                    {now < t.slotStart ? <ArrowLeftOnRectangleIcon className='h-4 w-4 text-yellow-600'/> : now > t.slotEnd ? <ArrowRightOnRectangleIcon className='h-4 w-4 text-yellow-600'/> : <HandThumbUpIcon className='h-4 w-4 text-green-600'/> }{t.selectedDate} {t.selectedTimeSlot && t.selectedTimeSlot}
                                                </p>
                                            }
                                            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{t.description}{t.amount === "bundle" ? " (purchased in a bundle)" : t.amount && t.currency && ` - ${formatCurrency(t.amount, t.currency)}`}</p>
                                            {t.customFields && t.customFields.filter(i => i.value).map(field => (
                                                <p key={field.key} className="text-sm text-neutral-600 dark:text-neutral-300  leading-relaxed">{field.label}: {field.value}</p> 
                                            ))}
                                            <p className="text-sm text-neutral-600 dark:text-neutral-300 leading-relaxed">{t.ticketId}</p>
                                            {t.refundDate && 
                                                <span className='flex items-center space-x-1'>
                                                    <ExclamationTriangleIcon className='h-4 w-4 text-yellow-600'/>
                                                    <p className="text-sm text-yellow-600 leading-relaxed">Refunded on {t.refundDate.toDate().toDateString()}</p>
                                                </span>
                                            }
                                        </span>
                                        {t.redeemed
                                            ?
                                                <NoSymbolIcon className='h-8 w-8'/>
                                            :
                                                <TicketIcon className='h-8 w-8'/>
                                        }
                                    </div>
                                </li>
                            ))}
                            {!overTwoHundred && tickets.length === 0 && <p className="text-neutral-600 dark:text-neutral-300 text-center mt-6">No tickets have been sold for {events.length === 1 ? "this event" : "these events"}.</p>}
                            {overTwoHundred && <p className="text-neutral-600 dark:text-neutral-300 text-center border border-neutral-700 dark:border-neutral-200 border-dashed rounded mx-4 p-3 mt-2">Use search or scan for check-in. Dynamic list not available when over 200 attendees.</p>}
                        </ul>
                    }
                </>
            }
        </div>
    )
}