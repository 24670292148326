export default function Privacy() {
    return (
        <div className="max-w-xl mx-auto my-12 p-4">
            <h1 className="mb-6 font-bold text-xl">Privacy Policy</h1>
            <p>This Privacy Policy ("Policy") explains how Sidebar Ticketing, LLC ("we", "our", "us") collects, uses, discloses and protects information collected through the Sidebar service (the "Service"), including in our app (the “App” or “Sidebar”), and your choices about the collection and use of your information.  By continuing to use this Site or the Service, you agree to this Policy.</p>
            <h2 className="mt-6 font-bold">1. INFORMATION WE COLLECT</h2>
            <p>Information you provide to us:</p>
            <ul className="list-disc list-outside ml-5 my-2 space-y-2">
                <li>When you add the Sidebar Ticketing App to your Stripe account, you provide us with your email address, the ability to read necessary details about your Stripe Payments, and the ability to listen to events such as purchases and refunds so that we can fulfill orders with tickets.</li>
                <li>When you purchase tickets to an event that is using Sidebar Ticketing, you provide us with your email address and the details of the transaction to purchase the ticket such as what was purchased, when it was purchased, and how much it cost.</li>
                <li>We also record when tickets are redeemed.</li>
                <li>If you submit feedback, we may contact you via email to resolve your issue.</li>
            </ul>
            <h2 className="mt-6 font-bold">2. HOW WE USE YOUR INFORMATION</h2>
            <p>We may use the information we collect, including information that identifies you or together with other information may identify you, in the following ways:</p>
            <ul className="list-disc list-outside ml-5 my-2 space-y-2">
                <li>To identify and authenticate you when you visit and use the Service.</li>
                <li>To analyze how you use the Service.</li>
                <li>To provide technical support for using the Service.</li>
                <li>To communicate with you about the Service through emails that you agree to receive,</li>
                <li>To prevent or detect fraud.</li>
                <li>To enforce this Policy, the Terms of Use and any other terms that you have agreed to, including to protect the rights, property, or safety of Sidebar, its users, or any other person, or the copyright-protected content of the Service; and as otherwise stated in this Policy.</li>
            </ul>
            <h2 className="mt-6 font-bold">3. HOW WE SHARE YOUR INFORMATION</h2>
            <p>We may share your personal information as follows:</p>
            <ul className="list-disc list-outside ml-5 my-2 space-y-2">
                <li>With our service providers for their use in performing internal business functions on our behalf. This includes: maintenance of our website, security, data analysis, email transmission, user feedback, CRM, database management services, credit card payment processing or data hosting.</li>
                <li>With law enforcement, government officials, or other third parties when (i) we are compelled to do so by a subpoena, court order or similar legal procedure, (ii) we need to do so to comply with law, (iii) if we believe in good faith that the disclosure of your personal information is necessary to prevent physical harm or financial loss or to report suspected illegal activity; (iv) to enforce this Policy or our Terms of Use or to protect the security or integrity of our Service; and/or (v) to defend against legal claims or demands, exercise or protect the rights, property or personal safety of Sidebar, our users or others.</li>
                <li>With third parties if we are involved in a sale, divestiture or transfer involving all or a portion of the company or our products and services, including corporate divestitures, mergers, acquisitions, bankruptcies, dissolutions, reorganizations, liquidations and similar transactions or proceedings.  In these situations, your information, such as your name, email address and other user information related to the Service may be among the items sold or otherwise transferred.</li>            
            </ul>
            <p>We may share, at our discretion, information that has been made anonymous, so that it does not identify a specific user through de-identification or aggregation ("De-Identified Data") and we do not limit our third party providers from using, selling, licensing, distributing or disclosing De-Identified Data.</p>
            <h2 className="mt-6 font-bold">4. HOW WE STORE AND PROTECT YOUR INFORMATION</h2>
            <p>We take reasonable precautions to protect your personal information.  For example, all personal information collected by us is transferred over https and stored by us or by a third party provider for us on a server in a secure environment. We provide access to such information only to our employees, our partners and any sub-contractors who need the information to perform a specific service. We do not have access to your payment card information. This information is provided directly to our payment provider, Stripe, who handles it in accordance with the Stripe privacy policy. Please keep in mind that the Internet is not a 100% secure medium for communication, and we cannot guarantee that your personal information will always remain private when using the Service.</p>
            <h2 className="mt-6 font-bold">5. YOUR CHOICES ABOUT YOUR INFORMATION</h2>
            <div className="space-y-2">
                <p>We will retain your personal information until the information no longer being necessary to accomplish the purpose for which it was provided. We may retain your information for longer periods to the extent that we are obliged to do so in accordance with applicable laws and regulations and/or we reasonably believe that retaining the information is necessary to protect our legal rights or legitimate business interests. Even if we delete your account, keep in mind that the deletion by our third party providers may not be immediate and that the deleted information may persist in backup copies for a reasonable period of time and as required by legal requirements that apply to such third parties.  Also, information that had already been shared with third parties may not be deleted by them.  For any privacy or data-protection-related questions, please write to hello@sidebarticketing.com</p>
                <p>Email: You can stop receiving promotional email communications from us by clicking on the "unsubscribe link" provided in such communications. We make efforts to promptly process all unsubscribe requests.  You may not opt out of Service-related communications (e.g., purchase and billing confirmations and reminders, changes/updates to features of the Service, and technical and security notices). If you have any questions about Service-related communications or unsubscribing from our communications, you can contact us directly at hello@sidebarticketing.com.</p>
                <p>Cookies and other technologies: Persistent cookies can be removed by following your web browser’s directions. You can reset your web browser to refuse all cookies or to indicate when a cookie is being sent. However, some features of the Service may not function properly if the ability to accept cookies is disabled. </p>
                <p>To find out how to see what cookies have been set and how to reject and delete the cookies, please visit: http://www.aboutcookies.org.</p>
                <p>Even if you disable the tracking, keep in mind that you may still receive interest-based advertising, including from third parties with whom your information had been previously disclosed and that you may still receive advertising from third parties, though such advertising may not be based on your interests and preferences. For any privacy or data-protection-related questions, please write to hello@sidebarticketing.com.</p>
            </div>
            <h2 className="mt-6 font-bold">6. CHILDREN’S PRIVACY</h2>
            <p>Sidebar does not knowingly collect or solicit any information from anyone under the age of 13 or knowingly allow such persons to register as users.  The Service and its content are not directed at children under the age of 13.  In the event that we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible.  If you believe that we might have any information from or about a child under 13, please contact us at hello@sidebarticketing.com.</p>
            <h2 className="mt-6 font-bold">7. LINKS TO OTHER WEB SITES AND SERVICES</h2>
            <p>We are not responsible for the practices employed by websites or services linked to or from the Service, including the information or content contained in such sites or services, and this Policy does not apply to them.  Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party’s own rules and policies.  Please read the terms of such websites carefully and exercise care when providing your personal information.</p>
            <h2 className="mt-6 font-bold">8. BROWSER DO NOT TRACK</h2>
            <p>Our Service does not support Do Not Track.  Do Not Track is a privacy preference you can set in your web browser to indicate that you do not want certain information about your webpage visits collected across websites when you have not interacted with that service on the page.  For all the details, including how to turn on Do Not Track, visit www.donottrack.us.  </p>
            <h2 className="mt-6 font-bold">9. CROSS BORDER</h2>
            <p>The Service is controlled, operated, and administered by Tim Raybould DBA Sidebar Company. from its offices within the United States. If you are accessing the Service from a location outside the United States, you hereby agree that your use of the Service and our collection and use of your personal information is subject to this Policy. By using the Service or submitting your personal information you consent to the transfer of your personal information to the United States where the protection to your personal information may be different from the country in which you reside.</p>
            <h2 className="mt-6 font-bold">10. YOUR CALIFORNIA PRIVACY RIGHTS</h2>
            <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our customers who are California residents to request and obtain from us once a year, free of charge, information about the personal information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year. If applicable, this information would include a list of the categories of personal information that was shared and the names and addresses of all third parties with which we shared information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request to: hello@sidebarticketing.com.</p>
            <h2 className="mt-6 font-bold">11. HOW TO CONTACT US</h2>
            <p>If you have any questions about this Policy or the Service, please contact us at hello@sidebarticketing.com.</p>
            <h2 className="mt-6 font-bold">12. CHANGES TO OUR PRIVACY POLICY</h2>
            <p>We may make changes to this Policy from time to time, in our sole discretion. When we make changes we deem material we will provide you with prominent notice as appropriate under the circumstances, e.g., by displaying a prominent notice on our Site or by sending you an email. In some cases, we will notify you in advance of the changes taking effect. Your continued use of the Service after the changes have been made will constitute your acceptance of the changes. Please therefore make sure you read any such notice carefully. If you do not wish to continue using the Service under the new version of the Policy, please cease using the Service.</p>
        </div>
    )
}