import { useState, useEffect, Fragment} from 'react'
import { collection, query, where, limit, getDocs, doc, getDoc, orderBy } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import Event from './Event'
import { Popover, Transition } from '@headlessui/react'
import QRCode from 'qrcode'
import { isMobile } from 'react-device-detect'

export default function App({uid, db, auth, functions, initializeWithEvent}) {
    const [events, setEvents] = useState([])
    const [selectedEvents, setSelectedEvents] = useState([])
    const [checkedEvents, setCheckedEvents] = useState([])
    const [scopedSearchKey, setScopedSearchKey] = useState("")
    const [qrCodeImage, setQrCodeImage] = useState(null)
    const [isLoadingQr, setIsLoadingQr] = useState(false)
    const [loginLink, setLoginLink] = useState()
    const [copiedState, setCopiedState] = useState(false)

    useEffect(() => {
        const q = query(collection(db, "events"), where("accountId", "==", uid), where("archived", "==", false), orderBy("lastModified", "desc"), limit(50))
        getDocs(q).then(querySnapshot => {
            setEvents(querySnapshot.docs.map(doc => ({ paymentLinkId: doc.id, ...doc.data() })))
        }).catch(error => {
            console.log(error)
        })
        getDoc(doc(db, "users", uid)).then(userDocSnap => {
            setScopedSearchKey(userDocSnap.data().typesenseScopedSearchKey || "")
        }).then(() => {
            if (initializeWithEvent) {
                setSelectedEvents([initializeWithEvent])
            }
        }).catch(error => {
            console.log(error)
        })
        
    },[db, uid, initializeWithEvent])

    const handleEventCheckboxClicked = (id) => {
        const el = document.getElementById(id)
        if (el.checked) {
            setCheckedEvents([...checkedEvents, id])
        } else {
            setCheckedEvents([...checkedEvents].filter(i => i !== id))
        }
    }

    function generateLoginCode () {
        setIsLoadingQr(true)
        const getLoginToken = httpsCallable(functions, 'getLoginToken')
        getLoginToken().then(result => {
            if (result.data !== "error") {
                const encodedString = window.btoa(encodeURIComponent(result.data))
                const url = `https://sidebarticketing.com/app?t=${encodedString}`
                setLoginLink(url)
                QRCode.toDataURL(url).then(code => {
                    setQrCodeImage(code)
                })
            }
        }).then(() => {
            setIsLoadingQr(false)
        }).catch(error => {
            setIsLoadingQr(false)
            console.log(error)
        })
    }

    const copyLoginLink = async () => {
        try {
          await navigator.clipboard.writeText(loginLink)
          setCopiedState(true)
          setTimeout(() => {
                setCopiedState(false)
          }, 2000)
        } catch (err) {
          console.error('Failed to copy: ', err)
        }
    }

    return (
        <div className='h-full'>
            {selectedEvents.length > 0
                ?
                    <Event events={selectedEvents} db={db} uid={uid} auth={auth} scopedSearchKey={scopedSearchKey} setSelectedEvents={setSelectedEvents} />
                :
                    <div className="m-4">
                        <div className="flex justify-between mb-6">
                            <h2 className="text-3xl font-extrabold tracking-tight">Check in</h2>
                            <div className='flex gap-2'>
                                <Popover className="relative">
                                    <Popover.Button className="inline-flex items-center rounded border border-neutral-900 dark:border-neutral-100 px-2.5 h-10 text-xs font-medium shadow-sm hover:bg-neutral-200 hover:dark:bg-neutral-800 focus:outline-none">
                                        {!isMobile && <span>Open on mobile (to scan tickets)</span>}
                                        {isMobile && <span>Share Sign-in Link</span>}
                                    </Popover.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute right-0 z-10 mt-5 flex w-72">
                                            <div className="flex flex-col justify-center text-center w-96 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                                    {!isLoadingQr && !qrCodeImage && <button className="underline" onClick={generateLoginCode}>Generate code</button>}
                                                    {isLoadingQr && <div>Loading...</div>}
                                                    {!isLoadingQr && qrCodeImage && 
                                                        <>
                                                            <div>Scan with phone camera</div>
                                                            <img src={qrCodeImage} alt="login QR code" className='w-96'/>
                                                            <button className="underline" onClick={copyLoginLink} disabled={copiedState}>
                                                                {copiedState ? "copied!" : "Copy link"}
                                                            </button>
                                                            <span>Expired? <button className="underline" onClick={generateLoginCode}>Re-generate</button></span>
                                                        </>
                                                    }
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </Popover>
                            </div>
                        </div>
                        {events.length === 0
                            ?
                                <p className="text-neutral-600 dark:text-neutral-300 text-center mt-6">No events found.</p>
                            :
                                <fieldset className='border-transparent'>
                                    <div className="divide-y divide-neutral-200 dark:divide-neutral-700 mb-10">
                                        {events.map(e => (
                                            <div key={e.paymentLinkId} className="relative flex items-center py-4 cursor-pointer" onClick={(clickEvent) => {
                                                if (clickEvent.target.type !== 'checkbox') {
                                                    const checkbox = document.getElementById(e.paymentLinkId);
                                                    if (checkbox) {
                                                        checkbox.checked = !checkbox.checked;
                                                        handleEventCheckboxClicked(e.paymentLinkId);
                                                    }
                                                }
                                            }}>
                                                <div className="min-w-0 flex-1 text-sm">
                                                    <span className='flex space-x-1'>
                                                        <p className="text-lg">{e.eventName}</p>
                                                        {!e.livemode && <div className='rounded bg-orange-200 font-semibold px-1 text-orange-800 flex items-center'>test</div>}
                                                    </span>
                                                    {e.eventStartDate && 
                                                        <p>{`${new Date(`${e.eventStartDate}T${e.eventStartTime || "00:00"}`).toDateString()}, ${new Date(`${e.eventStartDate}T${e.eventStartTime || "00:00"}`).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}</p>
                                                    }
                                                    {!e.eventStartDate && e.timeSlotPatterns && e.timeSlotPatterns.length > 0 &&
                                                        <p>Recurring event</p>
                                                    }
                                                </div>
                                                <input
                                                    id={e.paymentLinkId}
                                                    name={e.paymentLinkId}
                                                    type="checkbox"
                                                    defaultChecked={checkedEvents.includes(e.paymentLinkId)}
                                                    className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-neutral-500"
                                                    onChange={e => handleEventCheckboxClicked(e.target.id)}
                                                    onClick={(clickEvent) => clickEvent.stopPropagation()}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </fieldset>
                        }
                        <button
                            type="button"
                            onClick={() => setSelectedEvents(checkedEvents)}
                            className={`fixed bottom-0 left-0 w-full items-center ${checkedEvents.length === 0 ? "bg-neutral-200 dark:bg-neutral-800" : "bg-teal-300 text-neutral-900"} p-4 font-bold`}
                            disabled={checkedEvents.length === 0}
                        >
                            {`${checkedEvents.length === 0 ? "Choose events" : `Check in for ${checkedEvents.length} event${checkedEvents.length === 1 ? "" : "s"}`}`}
                        </button>
                    </div>
            }
        </div>
    )
}